import React, { useState } from 'react';
import './Home.css';
import { Menu, X } from 'lucide-react';


function Home() {
  const [mostrarMenu, setMostrarMenu] = useState(false); // Iniciar o menu como fechado

  const handleMostrarMenu = () => {
    setMostrarMenu(!mostrarMenu); // Alternar o estado do menu
  };

  return (
    <div className="home">
        <navbar>
            <h1 className='lumiert'>Lumiert</h1>        
            {mostrarMenu ? (
              <X onClick={handleMostrarMenu} className='close-hamburger' />
            ) : (
              <Menu onClick={handleMostrarMenu} className='hamburger' />
            )}

            <div className='navbar-items'>
            <div className='menu-button'><a href='/'>Home</a></div>
                <div className='menu-button'><a href='/dev'>Desenvolvimento</a></div>
                <div className='menu-button'><a href='/'>Música & Arte</a></div>
                <div className='menu-button'><a href='https://soberano.lumiert.com'>Soberano</a></div>
            </div>  
        </navbar>
        { mostrarMenu && (
              <div className='navbar-mobile-root'>
                <div className='navbar-mobile'>
                  <a href='/'>Home</a>
                  <a href='/dev'>Desenvolvimento</a>
                  <a href='/'>Música & Arte</a>
                  <a href='https://soberano.lumiert.com'>Soberano</a>
                </div>
              </div>
            )}  
        <div className='sobre'>
          
          <div className='eusou'><p>Eu sou</p> <span className='talcoisa'></span></div>

          <p className='heartreact'>Desenvolvido com 🫀 e ReactJS</p>    
          
        </div>

          <div className='bloco me'>
            <div className='row'>
              <div className='left'>
                <img className='yo' src='profile-pic.png'/>
              </div>


              <div className='right'>
                <div className='introduction'>
                  <h1 className='serif titulo'>Olá Mundo!</h1>
                  <p>Meu nome é Bernardo e tenho 18 anos, mas todos me chamam de Breno ou Lumi.</p>
                  <p>Trabalho na area de desenvolvimento de software, estou sempre aberto a novos projetos e oportunidades.</p>
                </div>

                <div className='haha-matrix-joke'>
                    <h1>I'll show you how deep the rabbit hole goes</h1>
                    <span className='hole'></span>
                </div> 
                <p className='reference'>- Matrix reference ;)</p>
              </div>
            </div>
          </div>
          <div className='bloco dark'>
            <div className='row'>
                <div className='right'>
                  <h1 className='serif titulo'>Sobre mim</h1>
                  <p>Estamos aprendendo coisas novas todos os dias, e com programação não é diferente. Grande parte da minha experiência no mercado de trabalho vem do Pascal (que sinceramente eu considero uma linguagem jurássica), mas que abriu portas para diversas oportunidades e me aproximou do mundo da tecnologia.</p>
                  
                  <h1 className='serif'>Um pouco de história:</h1>
                  
                  <p>Eu tive contato com computadores e tecnologia em geral desde pequeno, tive meu primeiro computador aos meus 4 anos de idade mais ou menos.</p>
                  <p>Um dia havíamos nos mudado para outra casa, eu e meus pais, na época acho que deveria ter uns 6 anos de idade. A primeira coisa que desencaixotei foi o computador, juntei todos os cabos, teclado, monitor e cabo de energia, depois de uns 5 minutos chamei minha mãe e mostrei minha obra de arte montada, eu lembro de ela perguntar com uma reação de espanto: "Você montou isso sozinho?"</p>
                  <p>Desde então comecei a me virar.</p>
                </div>
                <div className='left'>
                
                  <img className='round-image' src='me-again.jpg'/>

                  <p className='reference'>- Créditos ao meu fotógrafo de confiança: <a href='https://instagram.com/paulo_pelu'>@paulo_pelu</a></p>
                </div>
              </div>
            </div>
            
            <div className='bloco white'>
              <div className='row'>
                  <div className='left'>
                    <img className='round-image' src='me-walking.gif'/>
                    <p className='reference'>- Caminhada simples de 20km num dia de frio.</p>
                  </div>

                  <div className='right'>
                  <h1 className='serif titulo'>Quem eu sou?</h1>

                  <p>Por que eu vim parar aqui, eu não sei, mas meu propósito de vida provavelmente deve ser alguma coisa relacionada com tecnologia e computadores, eles dizem muito sobre mim, como eu vejo as coisas e o mundo, me inspira de maneira surpreendente.</p>
                  
                  <h1 className='serif'>Por que pensar demais?</h1>

                  <p>Hoje eu estou aqui escrevendo este portfólio, amanhã posso estar fazendo qualquer tarefa totalmente diferente do meu cotidiano que não condiz com a minha rotina. Nossas vidas se cruzam, talvez não por destino, mas pelos interesses em comum. Se eu tiver algo em mente, entendo o conceito, executo, aprecio, e preservo, como se fosse um troféu, minhas conquistas, projetos e virtudes têm um significado muito importante para mim.</p>  
                    
                  </div>

              </div>
            </div>

            <div className='bloco dark'>
              <div className='row'>
                <div className='right'>
                  <h1 className='serif titulo'>Motivação!</h1>

                  <p>Um portfólio não pode ser simplesmente uma página cheia de textos bonitos para substituir um currículo, não, nada disso, não gosto de superficialidade, eu quero transmitir algo único à cada pessoa que passar por aqui, minha vontade é de mudar seu dia para melhor não com palavras, mas com reflexões.</p>
                  <p>Nosso pensamento muda nossa forma de viver, boas vibrações nunca são demais.</p>
                </div>
                <div className='left'>
                  <img className='round-image' src='freddy-hehe.jpg'/>
                  <p className='reference'>- Esse é meu irmão mais novo, Freddy!</p>
                </div>

              </div>
            </div>  
            
            <div className='bloco white'>
              <div className='row'>
                <div className='left'>
                  <img className='round-image' src='cat-me.jpg'/>
                  <p className='reference'>- Eu e meu camarada russo, Boris Sidorovich 😎</p>
                </div>

                <div className='right'>
                  <h1 className='serif titulo'>Você não tem medo de si mesmo?</h1>
                  <p>Saber que qualquer coisa é possível, e o único responsável do seu destino é você mesmo me assusta, não pela livre e espontânea vontade que temos de mudar, mas sim de saber que muitas pessoas cavam suas próprias covas e se enterram com sua própria ignorância.</p>
                  <p>Ainda temos muito a explorar e conhecer, um passo ao desconhecido é tão empolgante quanto velejar ao alto mar durante o nascer do sol, no meio daquele oceano, quem está ali é só você, sua mente o deixa a mercê dessas dificuldades todos os dias e você talvez nem perceba, inventamos desculpas todos os dias para não sairmos da zona de conforto, quando na verdade, preferimos nos afundar sozinhos junto ao barco, aceitando o que somos, e não o que um dia poderemos nos tornar.</p>
                  </div>

              </div>
            </div>  

            <div className='bloco dark'>
              <div className='row'>
                <div className='right'>
                  <h1 className='serif titulo'>Música e Sentimentos</h1>
                  <p>A regra é clara, quanto mais você ouve música, mais fácil fica de reproduzi-la.</p>
                  <p>Digo isso não por gostar de diferentes conceitos e gêneros de música diferentes, mas sim por apreciar cada peculiaridade no que ouço.</p>
                  <p>É assim que eu vejo a vida, ângulos diferentes, sempre buscando inovar, saindo do conforto monótono para experimentar algo desconhecido. Se música é a linguagem universal, e transmite sentimentos sem nem mesmo precisar ter uma letra, quer dizer ainda temos muito o que aprender com isso.</p>
                </div>
                <div className='left'>
                  <img className='round-image' src='musica.jpg'/>
                  <p className='reference'>- Não importa o hardware, mas que venha do meu 🫀.</p>
                </div>

              </div>
            </div>              
    </div>  
  );
}

export default Home;
