import React, { useState } from 'react';
import '../Home.css'
import './Calc.css';
import { Delete, Equal, Plus, Minus, Divide, Radical, X } from 'lucide-react';

function Calculadora() {
    const [input, setInput] = useState('');
    const [temp, setTemp] = useState(null);
    const [operation, setOperation] = useState(null);

    const handleErase = () => {
        setInput(input.slice(0, -1));
    };

    const handleEraseAll = () => {
        setInput('');
    };

    const handleNumberClick = (char) => {
      if (input.length < 12 && !(char === ',' && input.includes(','))) {
        const newInput = input + char;
        const boomElement = document.getElementById('boom');
        const planeElement = document.getElementById('plane');

        switch (newInput) {
          case '7355608':
            setInput('BOOM!');
            if (boomElement) {
              boomElement.classList.add('explode');
              setTimeout(() => {
                boomElement.classList.remove('explode');
              }, 1000); // Tempo igual à duração da animação
            }
            break;
          case '404' :
            setInput('NOT FOUND!');
            break;
          case '4815162342' :
            setInput('LOST!');
            planeElement.classList.add('crash');
            setTimeout(() => {
                planeElement.classList.remove('crash');
            }, 7000);
            break;
          default:
            setInput(newInput);
            break;
        }
      }
    };

    const performCalculation = (a, b, operation) => {
        switch (operation) {
          case 'add':
            return a + b;
          case 'subtract':
            return a - b;
          case 'multiply':
            return a * b;
          case 'divide':
            return a / b;
          case 'squareRoot':
            return Math.sqrt(b);
          default:
            return b;
        }
    };

    const handleOperation = (action) => {
        if (input === '') return;
        const currentValue = parseFloat(input.replace(',', '.'));
    
        if (temp === null) {
          setTemp(currentValue);
        } else {
          const newValue = performCalculation(temp, currentValue, operation);
          setTemp(newValue);
          setInput(String(newValue).replace('.', ','));
        }
    
        setOperation(action);
        setInput('');
    };

    const handleEquals = () => {
        if (input === '' || temp === null || operation === null) return;
        const currentValue = parseFloat(input.replace(',', '.'));
        const newValue = performCalculation(temp, currentValue, operation);
        setInput(String(newValue).replace('.', ','));
        setTemp(null);
        setOperation(null);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        // Permitir apenas números e vírgulas
        if (/^\d*[,]?\d*$/.test(value)) {
          setInput(value);
        }
    };

    return (
        
        <div className='calc-frame'>
            
            <input type='text' value={input} onChange={handleChange} maxLength={10} readOnly/>
            <p className='calc-placeholder'>888888888888</p>
            <div className='calc-buttons'>
                <button onClick={() => handleEraseAll()}>AC</button><button onClick={() => handleOperation('multiply')}><X/></button><button onClick={() => handleOperation('divide')}><Divide/></button><button onClick={handleErase}><Delete/></button>
                <button onClick={() => handleNumberClick('7')}>7</button><button onClick={() => handleNumberClick('8')}>8</button><button onClick={() => handleNumberClick('9')}>9</button><button onClick={() => handleOperation('squareRoot')}><Radical/></button>
                <button onClick={() => handleNumberClick('4')}>4</button><button onClick={() => handleNumberClick('5')}>5</button><button onClick={() => handleNumberClick('6')}>6</button><button onClick={() => handleOperation('add')}><Plus/></button>
                <button onClick={() => handleNumberClick('1')}>1</button><button onClick={() => handleNumberClick('2')}>2</button><button onClick={() => handleNumberClick('3')}>3</button><button onClick={() => handleOperation('subtract')}><Minus/></button>
                <button onClick={() => handleNumberClick(',')}>,</button><button onClick={() => handleNumberClick('0')} className='calc-zero'>0</button><button onClick={handleEquals} className='calc-equal'><Equal/></button>
            </div>
        </div>
    );
}

export default Calculadora;